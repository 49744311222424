import styled from 'styled-components'
import { sizes } from '../components/Menu/styles'
import { device } from '../Styles/breakPoints'

export const Container = styled.div`
  margin-top: ${sizes.height};

  @media ${device.lg} {
    margin-top: 0;
    margin-left: ${sizes.width};
  }
`
