import React from 'react';

import { Container } from './styles'

const Category = ({ pageContext }) => {
  console.log(pageContext);

  return <Container>
    <h1>{pageContext.title}</h1>
    {pageContext.project && pageContext.project.map((project) => (
      <div key={project.slug}>
        <h3>{project.title}</h3>
        <h5>{project.shortDescription}</h5>
      </div>
    ))}
  </Container>;
}

export default Category;
